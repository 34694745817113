import { STORAGE_KEYS } from '../../Constants/StorageKeys'
import ApiClient from '../../axios/config'
import { snackActions } from '../utils/SnackBarUtils'
import { GetResourcesByUser } from './ResourcesService'
import { GetUser } from './UsersService'

export async function IsTokenValid() {
    
    const res = await ApiClient.get(`/validate`);

    return !!res;
}

export async function Login(user){
	try {
        const res = await ApiClient.post(`/login`, user);

        localStorage.setItem(STORAGE_KEYS.token, res.data.token);
        localStorage.setItem(STORAGE_KEYS.userId, res.data.id);
        
        const resources = await GetResourcesByUser(res.data.id);
        localStorage.setItem(STORAGE_KEYS.resources, JSON.stringify(resources));
        
        return {
            user: res.data.id,
            token: res.data.token,
            resources
        };
    } 
    catch (e) { 
        snackActions.error(e.message);       
        throw e;
    }
}

export async function LoginGoogle(tokenInfo){
	try {
        const res = await ApiClient.post(`/google-login`, tokenInfo);

        localStorage.setItem(STORAGE_KEYS.token, res.data.token);
        localStorage.setItem(STORAGE_KEYS.userId, res.data.id);
        
        const resources = await GetResourcesByUser(res.data.id);
        localStorage.setItem(STORAGE_KEYS.resources, JSON.stringify(resources));
        
        return {
            user: res.data.id,
            token: res.data.token,
            resources
        };
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetLoggedUser() {
    try {
        const id = await localStorage.getItem(STORAGE_KEYS.userId);
        const profile = await GetUser(id);

        return profile
    } catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetLoggedUserResources() {
    try {
        const res = await localStorage.getItem(STORAGE_KEYS.resources);
        const resources = JSON.parse(res)

        if (resources === null) {
            return {
                succeeded: false,
                status: 500,
                data: 'Resources not found'
            }
        }
        return {
            succeeded: true,
            data: resources
        }
    } catch (e) {
        return {
            succeeded: false,
            status: 500,
            data: e
        }
    }
}

export async function GetToken() {
    try {
        const token = await localStorage.getItem(STORAGE_KEYS.token);

        if (!token || token.trim() === '') {
            return {
                succeeded: false
            }
        }

        return {
            succeeded: true,
            data: token
        }
    } catch (e) {
        return {
            succeeded: false,
            status: 500,
            data: e
        }
    }
}

export async function Register(user){
	try {
        await ApiClient.post(`/register`, user);
        
        return {succeeded: true};
    } 
    catch (e) {
        snackActions.error(e.message);
        return {errors: e.response.data.data};
    }
}

export async function SignUpWithRole(user) {

    try {
        const res = await ApiClient.post(`register/roles`, user);
    
        snackActions.success(res.message);
        
        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function Logout() {
    try {
        localStorage.removeItem(STORAGE_KEYS.token);
        localStorage.removeItem(STORAGE_KEYS.userId);
        localStorage.removeItem(STORAGE_KEYS.userProfile);
        localStorage.removeItem(STORAGE_KEYS.resources);
    } 
    catch (e) {
        console.error(e);
    }
}