import {
	SET_JOBOPENING_ID,
	CREATE_JOBOPENING,
	CREATE_JOBOPENING_SUCCESS,
	CREATE_JOBOPENING_ERROR,
	UPDATE_JOBOPENING,
	UPDATE_JOBOPENING_SUCCESS,
	UPDATE_JOBOPENING_ERROR,
	SET_JOBOPENING,
	SET_SKILLS,
	SET_JOBOPENING_DESCRIPTION
} from './../types';


const initialState = {
	jobOpeningId: null,
	jobOpening: null,
	jobDescription: null,
	skills: [],
	error: null,
	loading: false,
};


export default function (state = initialState, action) {

	switch (action.type) {

		case SET_JOBOPENING:
			return {
				...state,
				jobOpening: action.payload
			};

		case SET_JOBOPENING_ID:
			return {
				...state,
				jobOpeningId: action.payload
			}

		case CREATE_JOBOPENING:
			return {
				...state,
				loading: true
			};

		case CREATE_JOBOPENING_SUCCESS:
			return {
				...state,
				loading: false,
				jobOpeningId: action.payload
			};
		
		case CREATE_JOBOPENING_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};

		case UPDATE_JOBOPENING:
			return {
				...state,
				loading: true
			};
		
		case UPDATE_JOBOPENING_SUCCESS:
			return {
				...state,
				loading: false,
				jobOpening: action.payload
			};
		
		case UPDATE_JOBOPENING_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};

		case SET_SKILLS:
			return {
				...state,
				skills: action.payload
			};
		
		case SET_JOBOPENING_DESCRIPTION:
			return {
				...state,
				jobDescription: action.payload
			}
		
	
		default:
			return state;
	}
}