import ApiClient from '../../axios/config';
import { snackActions } from '../utils/SnackBarUtils';

const CONTROLLER = 'users'

export async function GetUsersPaginated(params) {
    try {
        const res = await ApiClient.post(`${CONTROLLER}/paginated`, params);

        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetUsersNotInRole(roleId, params) {
    try {
        const res = await ApiClient.post(`${CONTROLLER}/roles/except/${roleId}`, params);

        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetUser(id) {
    try {
        const res = await ApiClient.get(`${CONTROLLER}/${id}`);

        return { succeeded: true, data: res?.data };
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetLoggedUserInfo() {
    try {
        const res = await ApiClient.get(`${CONTROLLER}/logged`);
        
        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function UpdateUser(id, user) {
    try {
        const res = await ApiClient.put(`${CONTROLLER}/${id}`, user);

        snackActions.success(res.message);

        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function ValidateReferralLUID(id, userId) {
    try {
        const res = await ApiClient.get(`${CONTROLLER}/referral/${id}/${userId}`);
        return res;
    } catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}