

export const SET_REDUX_REFRESH = 'SET_REDUX_REFRESH';

export const SET_IS_SAVING = 'SET_IS_SAVING';
export const SET_CREATE_FORM_CARD = 'SET_CREATE_FORM_CARD';


export const GET_SELECTED_APPLICATION_ID = 'GET_SELECTED_APPLICATION_ID';
export const SET_SELECTED_APPLICATION_ID = 'SET_SELECTED_APPLICATION_ID';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_ERROR = 'UPDATE_APPLICATION_ERROR';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const SET_APPLICATION = 'SET_APPLICATION';


export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';
export const UPDATE_CANDIDATE_SUCCESS = 'UPDATE_CANDIDATE_SUCCESS';
export const UPDATE_CANDIDATE_ERROR = 'UPDATE_CANDIDATE_ERROR';
export const SET_CANDIDATE = 'SET_CANDIDATE';
export const SET_CANDIDATE_SKILLS = 'SET_CANDIDATE_SKILLS';


export const SET_JOBOPENING_DESCRIPTION = 'SET_JOBOPENING_DESCRIPTION';

export const GET_APPLICATION_SKILLS = 'GET_APPLICATION_SKILLS';
export const SET_APPLICATION_SKILLS = 'SET_APPLICATION_SKILLS';

export const SET_SKILLS = 'SET_SKILLS';

export const SET_JOBOPENING = 'SET_JOBOPENING';
export const SET_JOBOPENING_ID = 'SET_JOBOPENING_ID';

export const CREATE_JOBOPENING = 'CREATE_JOBOPENING';
export const CREATE_JOBOPENING_ERROR = 'CREATE_JOBOPENING_ERROR';
export const CREATE_JOBOPENING_SUCCESS = 'CREATE_JOBOPENING_SUCCESS';

export const UPDATE_JOBOPENING = 'UPDATE_JOBOPENING';
export const UPDATE_JOBOPENING_SUCCESS = 'UPDATE_JOBOPENING_SUCCESS';
export const UPDATE_JOBOPENING_ERROR = 'UPDATE_JOBOPENING_ERROR';

export const DELETE_JOBOPENING = 'DELETE_JOBOPENING';

export const SET_CLIENT = 'SET_CLIENT';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR  = 'REMOVE_SNACKBAR ';

export const GET_ASSET_STATUS = 'GET_ASSET_STATUS';
export const SET_ASSET_STATUS = 'SET_ASSET_STATUS';

export const GET_ASSET_ASSIGNMENT_STATUS = 'GET_ASSET_ASSIGNMENT_STATUS';
export const SET_ASSET_ASSIGNMENT_STATUS = 'SET_ASSET_ASSIGNMENT_STATUS';
export const SET_ASSET_ASSIGNMENT_STATUS_NAME = 'SET_ASSET_ASSIGNMENT_STATUS_NAME';
export const SET_CREATE_ASSET_ELEMENT_FORM_CARD = 'SET_CREATE_ASSET_ELEMENT_FORM_CARD';