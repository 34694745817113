import {
	SET_CANDIDATE,
	SET_CANDIDATE_SKILLS,
	UPDATE_CANDIDATE,
	UPDATE_CANDIDATE_ERROR,
	UPDATE_CANDIDATE_SUCCESS
} from "../types";



const initialState = {
	candidate: null,
	skills: [],
	loading: false,
}

export default function (state = initialState, action) {

	switch (action.type) {

		case SET_CANDIDATE: 
			return {
				...state,
				candidate: action.payload
			}

		case UPDATE_CANDIDATE:
			return {
				...state,
				loading: true
			};

		case UPDATE_CANDIDATE_SUCCESS: 
			return {
				...state,
				candidate: action.payload,
				loading: false
			};
		
		case UPDATE_CANDIDATE_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false
			};
		
		case SET_CANDIDATE_SKILLS:
			return {
				...state,
				skills: action.payload
			};
	
		default:
			return state;
	}
}