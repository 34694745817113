import { createContext, useContext, useEffect, useState } from "react";
import { IsTokenValid, Login, LoginGoogle, Logout } from "../App/services/AuthService";
import { STORAGE_KEYS } from "../Constants/StorageKeys";


const AuthContext = createContext();


const AuthProvider = (props) => {

	const { history, children } = props;

	const [user, setUser] = useState(null);
	const [token, setToken] = useState(localStorage.getItem(STORAGE_KEYS.token) || '');
	const [resources, setResources] = useState([]);

	useEffect(() => {

		const validate = async () => {
			const res = await IsTokenValid();

			if (res) return;

			dropToken();
			
		};

		validate();

	}, []);

	const dropToken = () => {
		localStorage.removeItem(STORAGE_KEYS.token);
		setToken(null);
	}


	const login = async (data, google = false) => {
		try {

			const res = google ? 
				await LoginGoogle({ tokenId: data.credential }):
				await Login(data);

			setUser(res.user);
			setToken(res.token);
			setResources(res.resources);

		} 
		catch (error) {
			console.log(error);
		}
	}


	const logout = async () => {
		Logout();
		setUser(null);
		setToken("");
		setResources([]);
		
	}


	return (
		<AuthContext.Provider value={{ token, user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);