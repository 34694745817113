import ApiClient from '../../axios/config'
import { snackActions } from '../utils/SnackBarUtils'

const CONTROLLER = 'resources'

export async function GetResourcesPaginated(params) {
    try {
        const res = await ApiClient.post(`${CONTROLLER}/paginated`, params);
        return res.data;

    } catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function GetResourcesByUser(id) {
    try {
        const res = await ApiClient.get(`${CONTROLLER}/user/${id}`);
        return res.data;

    } catch (e) {
        snackActions.error(e.message);
        throw e;

    }
}

export async function GetResourcesMatrixByRole(id) {
    try {
        const res = await ApiClient.get(`${CONTROLLER}/matrix/role/${id}`);

        return res.data;
    } catch (e) {
        console.error(e)
        return {
            succeeded: false,
            status: 500,
            data: e
        }
    }
}

export async function CreateResourceMatrix(resourceMatrix) {
    try {
        const res = await ApiClient.post(`${CONTROLLER}/matrix`, resourceMatrix);

        snackActions.success(res.message);

        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);

        throw e;
    }
}

export async function UpdateResource(id, resource) {
    try {
        const res = await ApiClient.put(`${CONTROLLER}/${id}`, resource);
        snackActions.success('Resource updated');
        return res.data;

    } catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}


export async function UpdateResourcesMatrix(id, matrix) {
    try {
        const res = await ApiClient.put(`${CONTROLLER}/matrix/${id}`, matrix);

        snackActions.success(res.message);

        return res.data;
    } 
    catch (e) {
        snackActions.error(e.message);
        throw e;
    }
}

export async function DeleteResource(id) {
    try {
        const res = await ApiClient.delete(`${CONTROLLER}/${id}`);
        snackActions.success('Resource deleted');
        return res.data;

    } catch (e) {
        snackActions.error(e.message);
        throw e;

    }
}

export async function DeleteResourcesMatrix(id) {
    try {
        const res = await ApiClient.delete(`${CONTROLLER}/matrix/${id}`);

        snackActions.success(res.message);
    } 
    catch (e) {
        snackActions.error(e.message);

        throw e;
    }
}