import {
	SET_CLIENT
} from './../types/index';


const initialState = {
	client: {}
};


export default function (state = initialState, action) {

	switch (action.type) {

		case SET_CLIENT:
			return {
				...state,
				client: action.payload
			}
		default:
			return state;
	}
}