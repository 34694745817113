import {
	GET_APPLICATION_SKILLS,
	GET_SELECTED_APPLICATION_ID,
	SET_APPLICATION, SET_APPLICATION_SKILLS, SET_SELECTED_APPLICATION_ID, UPDATE_APPLICATION, UPDATE_APPLICATION_ERROR, UPDATE_APPLICATION_SUCCESS
} from "../types";



const initialState = {
	application: {},
	createForm: {},
	skills: [],
	selectedApplicationId: null,
	loading: false,
}


export default function ( state = initialState, action ) {

	switch (action.type) {

		case SET_APPLICATION:
			return {
				...state,
				application: action.payload
			};
		
		case UPDATE_APPLICATION:
			return {
				...state,
				loading: true
			};
		
		case UPDATE_APPLICATION_SUCCESS:
			return {
				...state,
				loading: false,
				application: action.payload,
			};
	
		case UPDATE_APPLICATION_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload
			};
		
		case SET_APPLICATION_SKILLS:
			return {
				...state,
				skills: action.payload
			};

		case GET_APPLICATION_SKILLS:
			return {
				...state
			};
		
		case SET_SELECTED_APPLICATION_ID:
			return {
				...state,
				selectedApplicationId: action.payload
			};

		case GET_SELECTED_APPLICATION_ID:
			return {
				...state,
			}
		

		default: return state;
	}
}