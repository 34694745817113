import {
	GET_ASSET_ASSIGNMENT_STATUS,
	GET_ASSET_STATUS,
	SET_ASSET_ASSIGNMENT_STATUS, SET_ASSET_ASSIGNMENT_STATUS_NAME,
	SET_ASSET_STATUS,
	SET_CREATE_ASSET_ELEMENT_FORM_CARD
} from "../types";



const initialState = {
	assetStatus: null,
	assetAssignmentStatus: null,
	assetAssignmentStatusName: null
}


export default function ( state = initialState, action ) {

	switch (action.type) {

		case SET_ASSET_STATUS:
			return {
				...state,
				assetStatus: action.payload
			};

		case GET_ASSET_STATUS:
		return {
			...state
		}
		
		case SET_ASSET_ASSIGNMENT_STATUS:
			return {
				...state,
				assetAssignmentStatus: action.payload
			};

		case GET_ASSET_ASSIGNMENT_STATUS:
		return {
			...state
		}
		
		case SET_ASSET_ASSIGNMENT_STATUS_NAME:
			return {
				...state,
				assetAssignmentStatusName: action.payload
			};

		case SET_CREATE_ASSET_ELEMENT_FORM_CARD:
			return {
				...state,
				createFormCard: action.payload
			};

		default: return state;
	}
}