// Helpers
import { API } from '../Settings/AppSettings';
import { STORAGE_KEYS } from '../Constants/StorageKeys';

// Libs
import axios from 'axios';
import { snackActions } from '../App/utils/SnackBarUtils';

const ApiClient = axios.create({
	baseURL: API
});


ApiClient.interceptors.request.use(async config => {
	const token = localStorage.getItem(STORAGE_KEYS.token);	
	
	config.headers = {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Authorization': `Bearer ${token}`
	}
	
	return config
}, error => Promise.reject(error));


ApiClient.interceptors.response.use(
	response => response.data,
	error => {

		if (error.response.status !== 401)
			return Promise.reject(error.response.data);

		if (!window.location.href.includes('signin'))
			window.location.href = `/signin`;
	}
)

export default ApiClient;