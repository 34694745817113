import { combineReducers } from 'redux';
import applicationReducer from './applicationReducer';
import candidateReducer from './candidateReducer';
import clientReducer from './clientReducer';
import formsReducer from './formsReducer';
import jobOpeningReducer from './jobOpeningReducer';
import layoutReducer from './layoutReducer';
import notificationReducer from './notificationReducer';
import companyAssetReducer from './companyAssetReducer';

export default combineReducers({
	jobOpenings: jobOpeningReducer,
	layout: layoutReducer,
	notifications: notificationReducer,
	clients: clientReducer,
	applications: applicationReducer,
	candidates: candidateReducer,
	forms: formsReducer,
	companyAsset: companyAssetReducer,
})