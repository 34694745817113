import React from 'react';
import ReactDOM from 'react-dom';

import {createRoot} from 'react-dom/client'
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import config from './config';

import store from './redux/store';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import './index.scss';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './App/utils/SnackBarUtils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthProvider from './providers/AuthProvider';


const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <AuthProvider>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <SnackbarProvider maxSnack={5} preventDuplicate={true} autoHideDuration={3000}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                            }}
                    >
                        <SnackbarUtilsConfigurator />
                        <App />
                    </SnackbarProvider>
                </GoogleOAuthProvider>
            </AuthProvider>
        </BrowserRouter>
    </Provider>
);
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(app);

// ReactDOM.render(app, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
