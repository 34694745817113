import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";


const PrivateRoute = ({ children, ...rest }) => {
	const auth = useAuth();

	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth.token ? 
				children 
				: <Redirect to={{ pathname: "/signin", state: { from: location } }} />
			}
		/>
	);
}

export default PrivateRoute;