import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import Page from './components/Page';
// import SignIn from '../Screens/Authentication/SignIn/SignIn';
import PrivateRoute from '../routing/PrivateRoute';
import { useAuth } from '../providers/AuthProvider';

const SignIn = React.lazy(() => import('../Screens/Authentication/SignIn/SignIn'));

const AdminLayout = Loadable({
	loader: () => import('./layout/AdminLayout'),
	loading: Loader
});

const App = () => {

	const auth = useAuth();

	
	return (
		<Aux>
			<ScrollToTop>
				<Suspense fallback={<Loader />}>
					<Switch>
						{/* {menu} */}

						<Route
							// key={index}
							path='/signin'
							exact={true}
							name='Signin'
							render={props =>
								auth.token ?
									<Redirect to="/" />
									: <Page {...props} component={SignIn} title='Signin' />
							}
						/>

						<PrivateRoute>
							<Route path="/" component={AdminLayout} />

						</PrivateRoute>

					</Switch>
				</Suspense>
			</ScrollToTop>
		</Aux>
	);
}

export default App;
