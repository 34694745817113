import { SET_CREATE_FORM_CARD, SET_IS_SAVING, SET_REDUX_REFRESH } from "../types";

const initialState = {
	createFormCard: {
		visible: false,
		formToDisplay: ''
	},
	showUnsavedChangesAlert: false,
	isSaving: false,
	refresh: {
		activityTab: 0
	}
};

export default function (state = initialState, action) {

	switch (action.type) {
		case SET_CREATE_FORM_CARD:
			return {
				...state,
				createFormCard: action.payload
			};
		case SET_IS_SAVING:
			return {
				...state,
				isSaving: action.payload,
			};

		case SET_REDUX_REFRESH:
			return {
				...state,
				refresh: {
					...action.payload
				}
			}
		default: return state;
	}
}